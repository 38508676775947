<template>
  <div>
    <!-- Old Iframe Implementation -->
    <!-- <div v-if="this.sidePanelDataObj.isSidePanelFrame"> -->
    <div v-if="sidePanelDataObj.isSidePanelFrame">
      <sidePanelIFrame
        id="billing-details-iframe"
        v-if="this.sidePanelDataObj.url"
        :url="this.sidePanelDataObj.url"
        :side-panel-data-obj="sidePanelDataObj"
        :is-iframe="this.sidePanelDataObj.isSidePanelFrame"
      ></sidePanelIFrame>
    </div>

    <!-- New Component -->
    <div v-else>
      <!-- Header Section -->
      <panelheader v-if="sidePanelDataObj.headerDataObj" :header-data-obj="sidePanelDataObj.headerDataObj"> </panelheader>

      <infoFilterDetails v-if="sidePanelDataObj.info" :data-obj="sidePanelDataObj"></infoFilterDetails>
      <!-- <info v-if="listDataObj.info" :list-data-obj="listDataObj" class="px-2"></info> -->

      <!-- Invoice Details -->
      <invoicedetails v-if="sidePanelDataObj.details" :data-obj="sidePanelDataObj"></invoicedetails>

      <!-- Tabs Section -->
      <tabs v-if="sidePanelDataObj.iFrame" :data-obj="sidePanelDataObj" :tab-data-obj="sidePanelDataObj.tabSectionDataObj" :iFrame="sidePanelDataObj.iFrame"></tabs>
    </div>
  </div>
</template>

<script>
  //Standard Components
  import EVENTS from "@/constants/events";

  //Project Earth
  import sidePanelIFrame from "@/components/_universal/sidepanel/sidepanel-iframe";

  //Template Layout Components
  import panelheader from "@/components/_universal/sidepanel/sidepanel-header";
  import tabs from "@/components/_universal/sidepanel/sidepanel-tabs";

  import infoFilterDetails from "@/components/infoFilters/infoFilterDetails.vue";

  import invoicedetails from "@/components/invoice/invoice-details.vue";

  //Controller
  import InvoiceController from "@/controllers/invoice-controller";

  //Initialize Controller and pass data to be loaded/displayed
  const invoiceController = new InvoiceController();

  export default {
    data() {
      return {
        sidePanelDataObj: {},
        EVENT_ACTION: {},
        iframeUrl: null,
      };
    },
    methods: {
      async loadData() {
        let sidePanel = this.$store.getters.getSidePanel;

        //Ensure the side bar is open
        if (sidePanel.isOpen === true) {
          //Load data from store, for side panel
          //Set Header Properties to be passed in as prop values
          this.sidePanelDataObj = await invoiceController.main(sidePanel.data);

          //Check if Side Panel should load Iframe
          if (this.sidePanelDataObj.isSidePanelFrame) {
            this.iframeUrl = this.sidePanelDataObj.url;
          }
        }
      },
    },
    components: {
      panelheader,
      infoFilterDetails,
      invoicedetails,
      tabs,
      sidePanelIFrame,
    },
    async mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window["analytics"].page(this.EVENT_ACTION.INVOICE.View);
      // }
    },
    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.loadData();
    },
  };
</script>
