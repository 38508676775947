<template>
  <div class="mx-2 mb-2 iframeHeightFix">
    <div class="row">
      <div v-if="dataObj.details.invoiceBillingRuleMessage" class="col-md-12 mb-1">
        <b-alert show class="mb-2" variant="warning">
          <span class="sub-title mr-2" v-html="dataObj.details.invoiceBillingRuleMessage"> </span>
        </b-alert>
      </div>
      <div v-if="dataObj.details.isLockedFromEditing" class="col-md-12 mb-1">
        <b-alert show class="mb-2" variant="danger">
          <span class="sub-title mr-2">
            This invoice is locked from editing.
          </span>
        </b-alert>
      </div>
      <div v-if="dataObj.details.projectId && dataObj.details.projectReferenceNumber" class="col-md-12 mb-1">
        <b-alert show class="mb-2" :variant="dataObj.details.isPaid ? 'success' : 'primary'">
          <i class="fa fa-check-circle mr-2"></i>
          <span class="sub-title mr-2">
            <span v-if="dataObj.details.isPaid" class="sub-title mr-2">
              This invoice is fully paid.
            </span>
            Job
            <a class="edit-link" target="_blank" :href="'/projects/details/' + dataObj.details.projectId" :id="`invoice-edit-project-button-row-id-${dataObj.details.projectId}`">{{
              dataObj.details.projectReferenceNumber
            }}</a>
            is associated with this invoice.
            <a class="edit-link" target="_blank" :href="'/projects/details/' + dataObj.details.projectId" :id="`invoice-edit-project-button-row-id-${dataObj.details.projectId}`">
              Click here to view the job.</a
            >
          </span>
        </b-alert>
      </div>

      <div class="col-md-12 mb-lg-0 mb-2">
        <b-card class="details">
          <div class="content">
            <div class="row">
              <div class="col-md-2">
                <div class="header d-flex">
                  <h5>Bill-to Address</h5>
                </div>
                <span v-if="dataObj.details.billingAddress.billingName">
                  {{ dataObj.details.billingAddress.billingName }}
                  <br />
                </span>
                <span v-if="dataObj.details.billingAddress.billingAddress">
                  {{ dataObj.details.billingAddress.billingAddress }}
                  <br />
                </span>
                <span v-if="dataObj.details.billingAddress.billingCity"> {{ dataObj.details.billingAddress.billingCity }}, </span>
                <span v-if="dataObj.details.billingAddress.billingProvince">
                  {{ dataObj.details.billingAddress.billingProvince }}
                  <br />
                </span>
                <span v-if="dataObj.details.billingAddress.billingStatePostalCode" class="mb-2 d-block">
                  {{ dataObj.details.billingAddress.billingStatePostalCode }}
                </span>
                <span v-if="dataObj.details.billingAddress.email">
                  <strong>Email:</strong>
                  {{ dataObj.details.billingAddress.email }}
                </span>
              </div>
              <div class="col-md-2">
                <div class="header d-flex">
                  <h5>Job Site Address</h5>
                </div>
                <span v-if="dataObj.details.jobsiteAddress.locationName">
                  {{ dataObj.details.jobsiteAddress.locationName }}
                  <br />
                </span>
                <span v-if="dataObj.details.jobsiteAddress.locationAddress">
                  {{ dataObj.details.jobsiteAddress.locationAddress }}
                  <br />
                </span>
                <span v-if="dataObj.details.jobsiteAddress.locationCity"> {{ dataObj.details.jobsiteAddress.locationCity }}, </span>
                <span v-if="dataObj.details.jobsiteAddress.locationProvince">
                  {{ dataObj.details.jobsiteAddress.locationProvince }}
                  <br />
                </span>
                <span v-if="dataObj.details.jobsiteAddress.locationStatePostalCode">
                  {{ dataObj.details.jobsiteAddress.locationStatePostalCode }}
                  <br />
                </span>
                <span v-if="dataObj.details.jobsiteAddress.email">
                  <strong>Email:</strong>
                  {{ dataObj.details.jobsiteAddress.email }}
                </span>
              </div>
              <div class="col-md-3 d-flex align-items-center">
                <div v-if="dataObj.details.isPaid" class="d-flex align-items-center text-center">
                  <img src="@/assets/images/paid-stamp.png" alt="" class="w-80 h-100 object-contian mx-auto" />
                </div>
              </div>
              <div class="col-md-3 ml-auto">
                <div class="header d-flex">
                  <h5>Invoice Date</h5>
                </div>
                <date-picker v-model="date" format="DD-MMM-YYYY" type="date" value-type="format" lang="en" class="hideDateBox mb-2" :disabled="isAllowedEdit" @input="updateInvoiceDate"> </date-picker>
                <div class="mb-2">
                  <span class="c-toolbar__state-title u-text-capitalize d-block mt-2">Client P.O</span>
                  <input class="form-control hideInputBox" id="slidepanel-invoice-details-client-po" type="text" v-model="dataObj.details.purchaseOrderReferenceNumber" @input="updatePOReferenceNumber" />
                  <!-- <strong>{{ dataObj.details.purchaseOrderReferenceNumber }}</strong> -->
                </div>
                <div>
                  <global-settings-modal id="slidepanel-edit-invoice-label" label="Label" :actionId="MODULE.INVOICE.UX.InvoiceLabels" />
                  <v-select v-model="selectedLabel" :options="labelsList" label="displayName" @input="onSelectLabel" :clearable="true"></v-select>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import editMixin from "@/mixin/edit-mixin";
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  const global = new GlobalServices();
  export default {
    mixins: [editMixin],
    data() {
      return {
        date: null,
        labelsList: [],
        selectedLabel: null,
      };
    },
    computed: {
      isAllowedEdit() {
        return this.dataObj.details.isLockedFromEditing;
      },
    },
    created() {
      this.MODULE = MODULE;
    },
    async mounted() {
      this.labelsList = await global.getUXDropDown(MODULE.INVOICE.UX.InvoiceLabels);
      if (this.dataObj) {
        if (this.dataObj.details.invoiceDate) this.date = this.dataObj.details.invoiceDate;
        if (this.dataObj.details.invoiceLabelId) this.selectedLabel = this.labelsList.find((label) => label.id === this.dataObj.details.invoiceLabelId);
      }
    },
    methods: {
      onSelectLabel(evt) {
        if (evt) {
          this.updateField("IntInvoiceLabelID", evt.id, "Invoice Label", evt.displayName, "IntInvoiceLabelID", null, null, null, null, MODULE.INVOICE.ActionId);
        } else {
          this.updateField("IntInvoiceLabelID", null, "Invoice Label", null, "IntInvoiceLabelID", null, null, null, null, MODULE.INVOICE.ActionId);
        }
      },
      updateInvoiceDate() {
        this.dataObj.data.date = this.date;
        this.updateField("InvoiceDate", this.dataObj.data.date, "Invoice Date", this.dataObj.data.date, "InvoiceDate");
      },
      updatePOReferenceNumber() {
        this.updateField(
          "purchaseOrderReferenceNumber",
          this.dataObj.details.purchaseOrderReferenceNumber,
          "Purchase Order Reference Number",
          this.dataObj.details.purchaseOrderReferenceNumber,
          "purchaseOrderReferenceNumber",
          null,
          null,
          null,
          null,
          MODULE.INVOICE.ActionId
        );
      },
    },
  };
</script>
<style lang="scss">
  .iframeHeightFix div {
    min-height: auto !important;
  }

  .hideDateBox {
    input {
      cursor: pointer;
      border: 0;
      box-shadow: none;
      padding: 0;
      height: auto !important;
      width: 150px;

      &:focus {
        .mx-calendar-icon {
          display: unset;
        }

        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px #8888885e;
        padding: 6px 30px;
        padding-left: 10px;
        height: 41px !important;
      }
    }

    

    .mx-calendar-icon,
    .mx-clear-wrapper {
      display: none !important;
    }
  }


  .hideInputBox {
      cursor: pointer;
      border: 0;
      box-shadow: none;
      padding: 0;
      height: auto !important;
      width: 150px;

      &:focus {
        .mx-calendar-icon {
          display: unset;
        }

        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px #8888885e;
        padding: 6px 30px;
        padding-left: 10px;
        height: 41px !important;
      }
    }
  
</style>
